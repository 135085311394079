import { render, staticRenderFns } from "./DemoStepperVertical.vue?vue&type=template&id=637d9682"
import script from "./DemoStepperVertical.vue?vue&type=script&lang=js"
export * from "./DemoStepperVertical.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports